<div class="form-body">
    <div class="website-logo">
        <a href="index.html">
            <div class="logo">
                <img class="logo-size" [attr.src]="tenantLogo" alt="">
            </div>
        </a>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="img-holder">
                <div class="info-holder">
                    <h3>{{ resourceData.login_text1 }}</h3>
                    <p>{{ resourceData.login_text2 }}</p>
                    <img src="../../../assets/images/graphic4.svg" alt="">
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="form-holder">
                <div class="form-content" style="background-color: #064E89 !important; margin-right: -1.5rem;">
                    <div class="form-items">
                        <div class="website-logo-inside">
                            <a href="index.html">
                                <div class="logo">
                                    <img class="logo-size" [attr.src]="tenantLogo" alt="">
                                </div>
                            </a>
                        </div>
                        <ng-container *ngIf="isTabEnable == 'signin'">
                            <div class="page-links">
                                <a href="index.html" class="active">{{ resourceData.login }}</a>
                            </div>
                            <form [formGroup]="loginForm">
                                <!-- <input class="form-control" type="text" name="username" placeholder="E-mail Address" required> -->
                                <input type="email" name="user" id="user" class="form-control mb-3 fw-bold text-dark" placeholder="E-mail Address" formControlName="usernameOrEmail" [ngClass]="{ 'is-invalid': submitted && f.usernameOrEmail.invalid }" />
                                <div *ngIf="submitted && f.usernameOrEmail.invalid" class="invalid-feedback mb-3">
                                    <div *ngIf="f.usernameOrEmail.errors?.required">{{ resourceData.email_required }}</div>
                                    <div *ngIf="f.usernameOrEmail.errors?.email">{{ resourceData.invalid_email_address }}</div>
                                </div>
                                <!-- <input class="form-control" type="password" name="password"  required> -->
                                <input type="password" name="password" id="password" class="form-control mb-3 fw-bold text-dark" placeholder="Password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.invalid }" />
                                <div *ngIf="submitted && f.password.invalid" class="invalid-feedback">
                                    <div *ngIf="f.password.errors?.required">{{ resourceData.password_required }}</div>
                                </div>
                                <div class="form-button">
                                    <ng-container *ngIf="isLoading">
                                        <button class="btn btn-light me-4" type="button">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ resourceData.loading}}
                          </button>
                                    </ng-container>
                                    <ng-container *ngIf="!isLoading">
                                        <button id="submit" type="submit" class="ibtn" style="color: #064E89;" (click)="login()">{{ resourceData.login }}</button>
                                    </ng-container>
                                    <a href="javascript:void(0)" (click)="changeTabForgotPass('forgot-pass')">{{ resourceData.forgot_pwd_link_txt }}</a>
                                </div>
                            </form>
                        </ng-container>

                        <ng-container *ngIf="isTabEnable == 'forgot-pass'">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h3>Password Reset</h3>
                                <button type="button" class="btn btn-light" (click)="changeTabForgotPass('signin')">Back</button>
                            </div>
                            <p class="mt-2">{{ resourceData.login_text3 }}</p>
                            <form [formGroup]="forgotEmail">
                                <input type="email" name="email" id="email" class="form-control mb-3 fw-bold text-dark" placeholder="E-mail Address" formControlName="email" [ngClass]="{ 'is-invalid': submittedFp && fp.email.invalid }" />
                                <div *ngIf="submittedFp && fp.email.invalid" class="invalid-feedback mb-3">
                                    <div *ngIf="fp.email.errors?.required">{{ resourceData.email_required }}</div>
                                    <div *ngIf="fp.email.errors?.email">{{ resourceData.invalid_email_address }}</div>
                                </div>
                                <div class="form-button w-100 mt-0">
                                    <ng-container *ngIf="isLoading">
                                        <button class="btn btn-light ibtn w-100 me-4" type="button" style="color: #064E89;">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                              {{ resourceData.loading}}
                            </button>
                                    </ng-container>
                                    <ng-container *ngIf="!isLoading">
                                        <button id="submit" type="submit" class="ibtn w-100" style="color: #064E89;" (click)="forgotPassword()">Send Reset Link</button>
                                    </ng-container>
                                </div>
                            </form>
                        </ng-container>

                        <!-- <div class="other-links">
                            <span>Or login with</span><a href="javascript:void(0)">Facebook</a><a href="javascript:void(0)">Google</a><a href="javascript:void(0)">Linkedin</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>