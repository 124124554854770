import { Component, OnInit } from '@angular/core';
import { tenantConfig } from 'src/app/Utils/general';

@Component({
  selector: 'app-crm-footer',
  templateUrl: './crm-footer.component.html',
  styleUrls: ['./crm-footer.component.css']
})
export class CrmFooterComponent implements OnInit {
  tenantLogo = tenantConfig.logoUrl;

  constructor() { }

  ngOnInit(): void {
  }

  gotoTop(){
    window.scroll(0, 0);
  }
}
