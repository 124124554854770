import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/services/userservice.service';
import { APP_CRM_URLS } from 'src/app/Utils/crm';
import * as ResourceJson from '../../../../Utils/crm-resource.json';
@Component({
  selector: 'app-inbox-notification-view',
  templateUrl: './inbox-notification-view.component.html',
  styleUrls: ['./inbox-notification-view.component.css'],
})
export class InboxNotificationViewComponent implements OnInit, OnDestroy {
  @Input() selectedNotifyData: any;
  @Input() selectedInboxData: any;
  @Input() selectedType: string;
  @Input() userDetails: any;
  @Output() closeInboxPopup = new EventEmitter();
  @ViewChild('mainNotifyCheck') mainNotifyCheckbox: ElementRef;
  page = 1;
  selectedNofityChecklist: any[] = [];
  selectedInboxChecklist: any[] = [];
  isNotifyLoading = false;
  inboxLoading = false;
  dummyData: any;
  resourceData: any = (ResourceJson as any).default; // used for read resource file
  userData: any;
  check: boolean;
  isShowCloseAll = false;

  constructor(private Us: UserService, private router: Router) {
    this.userData = JSON.parse(localStorage.getItem('userDetails'));
  }

  ngOnInit(): void {
    if (this.selectedType === 'notification') {
      this.getNotificationCount(this.page);
    } else if (this.selectedType === 'inbox') {
      this.getInboxcount();
      this.Us.setMailboxTab(false);
    }
  }

  ngOnDestroy(): void {
    // this.Us.setMailboxTab(false);
  }

  closeNotification(data: any) {
    const url =
      APP_CRM_URLS.closeNotification +
      '?type=em&id=' +
      data?.id +
      '&empid=' +
      this.userData?._id;
    this.Us.getCallWithHeaders(url).subscribe(
      (res: any) => {
        if (res?.type === 'success') {
          this.Us.showSuccess(this.resourceData.notification_closed);
          this.getNotificationCount(this.page);
        } else if (res?.type === 'already') {
          this.Us.showError(this.resourceData.already_notification_closed);
        } else {
          this.Us.showError(this.resourceData.notification_closed_not);
        }
      },
      (err) => {
        this.Us.showError(this.resourceData.unable_to_update);
      }
    );
  }

  getNotificationCount(pageno: number): void {
    const filter = {
      limit: 10,
      page: pageno,
      sortfield: 'created',
      direction: 'desc',
      criteria: [
        {
          key: 'emp_read.emp_id',
          value: this.userDetails._id,
          type: 'nin',
        },
        { key: 'active', value: 'active', type: 'eq' },
        { key: 'frontorback', value: 'frontandback', type: 'or' },
        { key: 'frontorback', value: 'back', type: 'or' },
      ],
    };
    this.isNotifyLoading = true;
    const url =
      APP_CRM_URLS.notifications +
      '/get?fr=an&filter=' +
      JSON.stringify(filter);
    this.Us.getCallWithHeaders(url).subscribe((res: any) => {
      if (res) {
        this.selectedNotifyData = res;
        this.dummyData = { ...res };
        if (!res?.final_noti?.length && this.page !== 1) {
          this.page = 1;
          this.getNotificationCount(this.page);
        }
        this.Us.setNotification({ res, value: 'notify' });
        this.isNotifyLoading = false;
      }
      this.isNotifyLoading = false;
    });
  }

  getNotifyChecklist(event: any) {
    if (event) {
      this.isShowCloseAll = true;
      this.check = event;
      this.selectedNofityChecklist = [...this.selectedNotifyData?.final_noti];
    } else {
      this.check = event;
      this.isShowCloseAll = false;
      this.selectedNofityChecklist = [];
    }
  }

  getSingleNotifyChecklist(event: any, index: number) {
    if (
      this.selectedNofityChecklist.includes(
        this.selectedNotifyData?.final_noti[index]
      )
    ) {
      this.selectedNofityChecklist = this.selectedNofityChecklist.filter(
        (val) => val?.id !== this.selectedNotifyData?.final_noti[index]?.id
      );
    } else {
      this.selectedNofityChecklist.push(
        this.selectedNotifyData?.final_noti[index]
      );
    }
    console.log(this.selectedNofityChecklist);
  }

  closeAllNotify() {
    let i = 0;
    let j = 0;
    const API_LIST: any = [];
    this.selectedNofityChecklist.forEach((data: any) => {
      const url =
        APP_CRM_URLS.closeNotification +
        '?type=em&id=' +
        data?.id +
        '&empid=' +
        this.userData?._id;
      API_LIST.push(this.Us.getCallWithHeaders(url));
    });
    forkJoin(API_LIST).subscribe(
      (res: any) => {
        res.forEach((element) => {
          if (element?.type === 'success') {
            i++;
          } else {
            j++;
          }
        });
        if (res?.length === this.selectedNofityChecklist?.length) {
          this.Us.showSuccess(i + ' ' + this.resourceData.notification_closed);
          if (
            this.page ===
              Math.ceil(this.selectedNotifyData?.notifyCount / 10) &&
            this.selectedNofityChecklist?.length ===
              this.dummyData?.final_noti?.length
          ) {
            this.page = 1;
            this.getNotificationCount(this.page);
          } else {
            this.getNotificationCount(this.page);
          }
        }
        if (j !== 0) {
          this.Us.showError(
            j + ' ' + this.resourceData.notification_closed_not
          );
        }
        this.getNotifyChecklist(false);
        this.mainNotifyCheckbox.nativeElement.checked = false;
      },
      (err) => {
        this.Us.showError(this.resourceData.unable_to_update);
      }
    );
  }
  /*
   ** Inbox Notification
   */

  getInboxcount(): void {
    const filter = {
      page: 1,
      sortfield: 'updated',
      direction: 'desc',
      criteria: [{ key: 'open_closed_thread', value: 'open', type: 'eq' }],
    };
    this.inboxLoading = true;
    const url = APP_CRM_URLS.inboxes + '?filter=' + JSON.stringify(filter);
    this.Us.getCallWithHeaders(url).subscribe((res: any) => {
      if (res?.inboxes.length) {
        res.inboxes = res?.inboxes.filter((inbox) => {
          if (
            inbox.context_type === 'STUDENT' ||
            inbox.context_type === 'student'
          ) {
            inbox.url = '/crm/student/' + inbox?.context_id?.student;
            return inbox;
          } else if (inbox.context_type === 'EMPLOYEE') {
            inbox.url = 'crm/employee/' + inbox?.context_id?.student;
            return inbox;
          } else if (inbox.context_type === 'AGENT') {
            // <!-- The Associate name has been changed as vendor for this Brugu tenant only -->
            inbox.url = 'crm/vendors/' + inbox?.context_id?.student;
            return inbox;
          } else if (inbox.context_type === 'CONTACT') {
            inbox.url = 'crm/leads/' + inbox?.context_id?.student;
            return inbox;
          } else {
            return inbox;
          }
        });
        this.selectedInboxData = res;
        this.dummyData = [
          ...this.selectedInboxData?.inboxes.slice(
            10 * (this.page - 1),
            10 * (this.page - 1) + 10
          ),
        ];
        this.inboxLoading = false;
        if (!this.dummyData?.length && this.page !== 1 && !this.inboxLoading) {
          this.page = 1;
          this.getInboxcount();
        }
        this.Us.setNotification({ res, value: 'inbox' });
      }
    });
  }

  getInboxChecklist(event: any) {
    if (event) {
      this.isShowCloseAll = true;
      this.check = event;
      this.selectedInboxChecklist = [
        ...this.selectedInboxData?.inboxes.slice(
          10 * (this.page - 1),
          10 * (this.page - 1) + 10
        ),
      ];
      this.dummyData = [...this.selectedInboxChecklist];
    } else {
      this.check = event;
      this.isShowCloseAll = false;
      this.selectedInboxChecklist = [];
    }
    console.log(this.selectedInboxChecklist);
  }

  // change Page
  pageChange(e: any) {
    this.page = e;
    this.getNotifyChecklist(false);
    this.mainNotifyCheckbox.nativeElement.checked = false;
    if (this.selectedType === 'notification') {
      this.selectedNotifyData = {};
      this.getNotificationCount(this.page);
    }
  }
  pageChanged(eve) {
    console.log(eve);
  }

  closeInbox(data: any) {
    const url = APP_CRM_URLS.closeInboxes + '/' + data?._id;
    this.Us.putCallwithHeaders(url, { open_closed_thread: 'closed' }).subscribe(
      (res: any) => {
        if (res?.respCode === 202) {
          this.Us.showSuccess(this.resourceData.inbox_closed);
          this.getInboxcount();
        } else {
          this.Us.showError(this.resourceData.inbox_not_closed);
        }
      },
      (err) => {
        this.Us.showError(this.resourceData.unable_to_update);
      }
    );
  }

  closeAllInboxNotify() {
    let i = 0;
    let j = 0;
    const API_LIST: any = [];
    this.selectedInboxChecklist.forEach((data: any) => {
      const url = APP_CRM_URLS.closeInboxes + '/' + data?._id;
      API_LIST.push(
        this.Us.putCallwithHeaders(url, { open_closed_thread: 'closed' })
      );
    });
    forkJoin(API_LIST).subscribe(
      (res: any) => {
        res.forEach((element) => {
          if (element?.respCode === 202) {
            i++;
          } else {
            j++;
          }
        });
        if (res?.length === this.selectedInboxChecklist?.length) {
          this.Us.showSuccess(i + ' ' + this.resourceData.inbox_closed);
          if (
            this.page ===
              Math.ceil(
                this.selectedInboxData?.pagination?.totalRecords / 10
              ) &&
            this.selectedInboxChecklist?.length === this.dummyData?.length
          ) {
            this.page = 1;
          }
          this.getInboxcount();
        }
        if (j !== 0) {
          this.Us.showError(j + ' ' + this.resourceData.inbox_not_closed);
        }
        this.getNotifyChecklist(false);
        this.mainNotifyCheckbox.nativeElement.checked = false;
      },
      (err) => {
        this.Us.showError(this.resourceData.unable_to_update);
      }
    );
  }

  getSingleInboxChecklist(data: any) {
    if (this.selectedInboxChecklist.includes(data)) {
      this.selectedInboxChecklist = this.selectedInboxChecklist.filter(
        (val) => val?._id !== data?._id
      );
    } else {
      this.selectedInboxChecklist.push(data);
    }
  }

  openNotification(data: any) {
    this.Us.setUserDetails({});
    if (this.selectedType === 'inbox') {
      this.Us.setMailboxTab(true);
    } else if (this.selectedType === 'notification') {
      this.Us.setMailboxTab(false);
    }
    this.router.navigate([data?.url]);
    this.closeInboxPopup.emit();
  }
}
