import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAlphabetOnly]',
})
export class AlphabetOnlyDirective {
  key;
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    this.key = event.keyCode;
    if (
      (this.key >= 15 && this.key <= 31) || (this.key >= 33 && this.key <= 64) ||
      this.key >= 123 ||
      (this.key >= 96 && this.key <= 105)
    ) {
      event.preventDefault();
    }
  }
}
// export class AlphabetOnlyDirective {
//   constructor(private elRef: ElementRef) {}

//   @HostListener('input', ['$event'])
//   onInputChange(event: { stopPropagation: () => void }) {
//     const initalValue = this.elRef.nativeElement.value;
//     this.elRef.nativeElement.value = initalValue.replace(/[^A-Za-z]*$/, '');
//     if (initalValue !== this.elRef.nativeElement.value) {
//       event.stopPropagation();
//     }
//   }
// }
