const baseUrl = '/api/';
export const TenantId = 578735;

export const tenantConfig = {
  siteUrl: 'app.brugu.io',
  name: 'Brugu',
  logoUrl: ' ../../assets/image/brugu-logo.png',
  logoOnly: ' ../../assets/image/brugu-logo.png',
  email: 'kiranthecoder@gmail.com',
  tenantId: TenantId,
  company: 'app.brugu.io',
  phone: '+919959688870',
  placementEmail: 'placement@abacademy.co.uk',
  landline: '+919959688870',
  tenantAddress: 'Hyderabad, Telangana, India',
  website: 'https://brugu.io',
};

export const registerDetails = {
  registerPlace: 'England & Wales',
  registerNo: '08811442',
  VATRegisterNo: '297924246',
};

export const roleConfig = {
  agent: 'Agent',
  employee: 'Employee',
  student: 'Student',
  admin: 'Admin',
};

export const employeeOfConfig = {
  value: 'brugu',
  key: 'Brugu',
};

export const APP_URLS = {
  FILE_PATH_LINODE: `https://daipp-data-buckets.eu-central-1.linodeobjects.com/${TenantId}/`,
  login: baseUrl + 'v1/employee/signin',
  tenantSettings: baseUrl + 'tenantsettings',
  tenantPermission: baseUrl + 'tenantPermission',
  forgotPassword: baseUrl + 'employee/forgotPassword',
  groupedList: baseUrl + 'groupedlist',
  student: baseUrl + 'students',
  Courses: baseUrl + 'courses',
  Categories: baseUrl + 'vone/course/category',
  profilePermissions: baseUrl + 'vone/profile/permission',
  CourscesList: baseUrl + 'vone/course/student?filter=',
  favmulti: baseUrl + 'vone/course/favmulti',
  newchapters: baseUrl + 'vone/lmsmain?filter=',
  studentforGotPassword: baseUrl + 'students/forgotPassword?email=',
  v1: {
    tenantSettings: baseUrl + 'v1/tenantsettings',
    getHelpList: baseUrl + 'v1/help/list',
    userDetails: baseUrl + 'v1/user/details',
    login: baseUrl + 'v1/user/signin',
    changevlepwd: baseUrl + 'v1/user/changevlepassword',
    settingsData: baseUrl + 'v1/settingsdata',
  },
};

export const isEmptyObjectValue = (obj: any): boolean => {
  const arrObj = Object.values(obj);
  for (const elem of arrObj) {
    if (
      elem !== '' &&
      elem !== null &&
      elem !== undefined &&
      elem.toString().trim()
    ) {
      return false;
    }
  }
  return true;
};

/**
 * Checking for file allowed
 */
export const isFileAllowed = (fileName: string, type: string = '') => {
  let _isFileAllowed = false;
  let allowedFiles = [];
  if (type === 'bckUpload') {
    allowedFiles = ['.xls', '.xlsx', '.csv'];
  } else {
    allowedFiles = [
      '.ppt',
      '.pptx',
      '.bmp',
      '.doc',
      '.docx',
      '.gif',
      '.jpeg',
      '.jpg',
      '.pdf',
      '.rtf',
      '.png',
      '.tif',
      '.tiff',
      '.txt',
      '.xls',
      '.xlsx',
      '.csv',
    ];
  }

  const regex = /(?:\.([^.]+))?$/;
  const extension = regex.exec(fileName);
  if (undefined !== extension && null !== extension) {
    for (const ext of allowedFiles) {
      if (ext === extension[0]) {
        _isFileAllowed = true;
      }
    }
  }
  return _isFileAllowed;
};

/**
 * Checking for file size
 */
export const isFileSizeAllowed = (size: number) => {
  let _isFileSizeAllowed = false;
  if (size <= 5105344) {
    _isFileSizeAllowed = true;
  }
  return _isFileSizeAllowed;
};

export const validateEmail = (elementValue) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
};

export const sanititzeFileName = (value) => {
  const index = value?.lastIndexOf(TenantId);
  const fileName = value?.substr(index + 7);
  if (index === -1) {
    return value;
  } else {
    return fileName;
  }
};

export const htmlToText = (value) => {
  return value
    ?.replace(/<[^>]+>/g, '')
    ?.replace(/\&nbsp;/g, ' ')
    ?.replace(/\&#160;/g, ' ');
};
