<form [formGroup]="partner_info" class="row g-3 needs-validation" novalidate>
    <div class="row mt-3">
        <div class="col-md-6">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="name" placeholder="Name" formControlName="name"
                    [ngClass]="{ 'is-invalid': submitted && f.name.invalid }" maxlength="30" required appAlphabetOnly>
                <label for="name">Lead Name<span style="color: red;">*</span></label>
                <div *ngIf="submitted && f.name.invalid" class="invalid-feedback mb-3">
                    <span *ngIf="f.name.errors.required">{{ resourceData.partner_name_required }}</span>
                    <span *ngIf="f.name.errors.pattern">{{ resourceData.Invalid_data }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-floating mb-3">
                <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.invalid }" required>
                <label for="email">Lead Email<span style="color: red;">*</span></label>
                <div *ngIf="submitted && f.email.invalid" class="invalid-feedback mb-3">
                    <span *ngIf="f.email.errors.required">{{ resourceData.client_email_required }}</span>
                    <span *ngIf="f.email.errors?.pattern">{{ resourceData.Invalid_data }}</span>
                    <span *ngIf="f.email.errors?.email">{{ resourceData.invalid_email_address }}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-md-6">
            <div class="d-flex form-floating">
                <input [attr.type]="eventChange" class="form-control boderChanges" id="floatingPassword"
                    formControlName="password" placeholder="Password">
                <label for="floatingPassword">Password<span style="color: red;">*</span></label>
                <span class="input-group-text eyeBorderChanges" style="border-color: darkgray;"
                    (click)="password_show_hide()">
                    <i [attr.class]="eyeIcon" id="show_eye"></i>
                </span>
            </div>
        </div>
    </div> -->
    <div class="row">
        <div class="col-md-6">
            <div class="form-floating mb-3">
                <input type="tel" class="form-control" id="phone" placeholder="Contact Number" formControlName="phone"
                    [ngClass]="{ 'is-invalid': submitted && f.phone.invalid }" maxlength="15" required numbersOnly>
                <label for="phone">Lead Contact Number<span style="color: red;">*</span></label>
                <div *ngIf="submitted && f.phone.invalid" class="invalid-feedback mb-3">
                    <span *ngIf="f.phone.errors.required">{{ resourceData.client_mobile_number_required }}</span>
                    <span *ngIf="f.phone.errors?.minlength">{{ resourceData.contact_no_min_length }}</span>
                    <span *ngIf="f.phone.errors?.maxlength">{{ resourceData.contact_no_max_length }}</span>
                    <span *ngIf="f.phone.errors?.pattern">{{ resourceData.Invalid_data }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="Contact_name" placeholder=" contact Name"
                    formControlName="company_name" appAlphabetOnly maxlength="30">
                <label for="partner_name">Company Name</label>
                <!-- <div *ngIf="submitted && f.company_name.invalid" class="invalid-feedback mb-3">
                    <span *ngIf="f.company_name.errors.required">{{ resourceData.company_required }}</span>
                    <span *ngIf="f.company_name.errors.pattern">{{ resourceData.Invalid_data }}</span>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-floating mb-3">
            <input type="text" class="form-control" id="contact_Id" placeholder=" contact Id"
                formControlName="contact_Id" maxlength="30">
            <label for="partner_name">Lead Reference Id</label>
            <!-- <div *ngIf="submitted && f.company_name.invalid" class="invalid-feedback mb-3">
                <span *ngIf="f.company_name.errors.required">{{ resourceData.company_required }}</span>
                <span *ngIf="f.company_name.errors.pattern">{{ resourceData.Invalid_data }}</span>
            </div> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating mb-3">
            <input type="text" class="form-control" id="whatsapp_Link" placeholder="whatsapp Link"
                formControlName="whatsapp_Link" maxlength="30">
            <label for="partner_name">whatsapp Link</label>
            <!-- <div *ngIf="submitted && f.company_name.invalid" class="invalid-feedback mb-3">
                <span *ngIf="f.company_name.errors.required">{{ resourceData.company_required }}</span>
                <span *ngIf="f.company_name.errors.pattern">{{ resourceData.Invalid_data }}</span>
            </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-floating mb-3">
            <input type="text" class="form-control" id="telegram_Link" placeholder="Telegram Link"
                formControlName="telegram_Link" maxlength="30">
            <label for="partner_name">Telegram Link</label>
            <!-- <div *ngIf="submitted && f.company_name.invalid" class="invalid-feedback mb-3">
                <span *ngIf="f.company_name.errors.required">{{ resourceData.company_required }}</span>
                <span *ngIf="f.company_name.errors.pattern">{{ resourceData.Invalid_data }}</span>
            </div> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating mb-3">
            <input type="text" class="form-control" id="contact_time" placeholder="Time"
                formControlName="contact_time" disabled>
            <label for="contact_time">Time</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-floating mb-3">
            <select class="form-select" id="occupation" formControlName="occupation" (change)="selectAwards(partner_info.get('occupation').value)" [ngClass]="{ 'is-invalid': submitted && f.occupation.invalid }">
            <option value="" disabled>{{ resourceData.select }}</option>
            <option value="Business Role">Business Role</option>
            <option value="Individual">Individual</option>
            <option value="Middle man">Middle man</option>
          </select>
            <label for="occupation">Occupation<span style="color:red">*</span></label>
            <div *ngIf="submitted && f.occupation.invalid" class="invalid-feedback mb-3">
                <div *ngIf="f.occupation?.errors?.required">{{ resourceData?.occupation_is_required }}</div>
            </div>
        </div>
  </div>
    </div>
    <div class="row" *ngIf="occupationData === true">
      <div class="col-md-6">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="businessName" placeholder="business Name"
              formControlName="businessName" appAlphabetOnly maxlength="30">
          <label for="businessName">Business Name</label>
          <!-- <div *ngIf="submitted && f.businessName.invalid" class="invalid-feedback mb-3">
              <span *ngIf="f.businessName.errors.required">{{ resourceData.businessName_required }}</span>
              <span *ngIf="f.businessName.errors.pattern">{{ resourceData.businessName_invalid_data }}</span>
          </div> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating mb-3">
          <input type="url" class="form-control" id="businessWebsite" placeholder="Business website" formControlName="businessWebsite" [ngClass]="{ 'is-invalid': submitted && f.businessWebsite.invalid }">
          <label for="businessWebsite">Business website</label>
          <div *ngIf="submitted && f.businessWebsite.invalid" class="invalid-feedback mb-3">
              <!-- <div *ngIf="f.businessWebsite.errors.required">{{ resourceData.businessWebsite_required }}</div> -->
              <div *ngIf="f.businessWebsite.errors?.pattern">{{ resourceData?.invalid_businessWebsite }}</div>
          </div>
      </div>
      </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="address" placeholder="Address" formControlName="address"
                    maxlength="50">
                <label for="address">Address</label>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="city" placeholder="City" formControlName="city"
                    maxlength="20" appAlphabetOnly
                    maxlength="30">
                <label for="city">City/Town</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="state" placeholder="State" formControlName="state"
                    maxlength="20" appAlphabetOnly
                    maxlength="30">
                <label for="state">State</label>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="country" placeholder="Country" formControlName="country"
                    maxlength="15" [ngClass]="{ 'is-invalid': submitted && f.country.invalid }" required appAlphabetOnly
                    maxlength="30">
                <label for="country">Country<span style="color: red;">*</span></label>
                <div *ngIf="submitted && f.country.invalid" class="invalid-feedback mb-3">
                    <span *ngIf="f.country.errors.required">{{ resourceData.country_required }}</span>
                    <span *ngIf="f.country.errors.pattern">{{ resourceData.Invalid_data }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-floating mb-3">
            <textarea class="form-control minh80" placeholder="Message Box" id="messageBox" formControlName="messageBox"></textarea>
            <label for="description">Note</label>
        </div>
    </div>
    </div>

    <div class="row">
        <div class="col-md-6 col-12 mt-2">
            <ng-container *ngIf="isLoading">
                <button class="btn btn-primary me-4" type="button">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{ resourceData.loading }}
                </button>
            </ng-container>
            <ng-container *ngIf="!isLoading">
                <button type="button" class="btn btn-primary" type="submit" (click)="submittedPartnerData()"
                    *ngIf="!PartnerData?.isEdit">Add</button>
                <!-- <button type="button" class="btn btn-primary" type="submit" (click)="editSubmittedPartnerData()" *ngIf="PartnerData?.isEdit">Update</button> -->
            </ng-container>
        </div>
    </div>
</form>
