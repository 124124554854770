import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { UserService } from 'src/app/services/userservice.service';
import { APP_CRM_URLS } from 'src/app/Utils/crm';
import {
  APP_URLS,
  tenantConfig,
  employeeOfConfig,
} from 'src/app/Utils/general';
import { mainPermissions } from 'src/app/Utils/permissions';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-crm-navbar',
  templateUrl: './crm-navbar.component.html',
  styleUrls: ['./crm-navbar.component.css'],
})
export class CrmNavbarComponent implements OnInit, OnDestroy {
  constructor(
    private userService: UserService,
    private router: Router,
    private location: Location
  ) {
    // tslint:disable-next-line:prefer-const
    // let getUserDetails: any = localStorage.getItem('userDetails');
    // this.userDetails = JSON.parse(getUserDetails);

    this.loggedUserInfo$ = this.userService.loggedUserDetails.subscribe(
      (user: any) => {
        if (Object.keys(this.userDetails).length) {
          this.userDetails = user;
        } else {
          const getUserDetails: any = localStorage.getItem('userDetails');
          this.userDetails = JSON.parse(getUserDetails);
        }
      }
    );

    /**
     * Get permission data from local object
     */
    this.isEnable = false;
    this.permissionInfo$ = this.userService.userPermission.subscribe(
      (data: any) => {
        if (data) {
          if (Object.keys(data)?.length > 0) {
            this.permissionDetails = data;
            this.isEnable = true;
          }
        }
      }
    );
  }
  userDetails: any = [];
  permissions: any = {};
  isEnable = false;
  permissionDetails: any = [];
  permissionInfo$: Subscription;
  loggedUserInfo$: Subscription;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  closenav: boolean;
  isEditPopupOpen: boolean;
  selector = '';

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.permissionInfo$?.unsubscribe();
    this.loggedUserInfo$?.unsubscribe();
  }

  validateMenuPrivilage(key1: any, key2: any) {
    let isPrivillageRequired = false;
    if (this.userDetails?.role?.value !== 'Admin') {
      if (
        this.userDetails?.employee_of === employeeOfConfig.value &&
        this.userDetails?.type === 'Employee'
      ) {
        isPrivillageRequired = true;
      } else if (
        this.userDetails?.employee_of === 'associate' &&
        this.userDetails?.type === 'Employee'
      ) {
        isPrivillageRequired = true;
      }

      if (
        this.userDetails?.type === 'Agent' &&
        this.userDetails?.parents?.length &&
        this.userDetails?.parents[0]?.type === 'Agent'
      ) {
        isPrivillageRequired = true;
      } else if (this.userDetails?.role?.value === 'Agent') {
        isPrivillageRequired = true;
      } else if (
        this.userDetails?.employee_of === '' &&
        this.userDetails?.type === 'Employee'
      ) {
        isPrivillageRequired = true;
      }
    }

    if (this.userDetails?.role?.value === 'Admin' || isPrivillageRequired) {
      if (
        this.permissionDetails[key1] &&
        this.permissionDetails[key1][key2] &&
        this.permissionDetails[key1][key2] === true
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  setGlobalSearchEmpty(end = ''): void {
    this.userService.setUserDetails({});
    this.userService.setGlobalSearchEmpty('');
    $('#sidemenu').offcanvas('hide');
    this.isEditPopupOpen = false;
    if (end === 'viewall') {
      this.opensidenav();
    }
  }

  /**
   * Open popup for side navbar
   */
  opensidenav() {
    this.isEditPopupOpen = true;
    $('#sidemenu').offcanvas('show');
  }

  /**
   * Close popup for side navbar
   */
  closesidenav() {
    $('#sidemenu').offcanvas('hide');
    this.isEditPopupOpen = false;
  }
}
