import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TenantId, APP_URLS, tenantConfig } from '../../Utils/general';
import { Router } from '@angular/router';
import { UserService } from './../../services/userservice.service';
import { NgForm } from '@angular/forms';
import * as ResourceJson from '../../Utils/crm-resource.json';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
})
export class SigninComponent implements OnInit {
  tenantLogo = tenantConfig.logoUrl;

  @ViewChild('myform') contactForm: NgForm;

  loginForm!: FormGroup;
  submitted = false;
  submittedFp = false;
  isLoading = false;
  tenantSettingsData: any = {};
  isTabEnable = 'signin';
  forgotEmail: FormGroup;
  resourceData: any = (ResourceJson as any).default; // used for read resource file

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private Us: UserService
  ) {}

  get f() {
    return this.loginForm.controls;
  }

  get fp() {
    return this.forgotEmail.controls;
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      usernameOrEmail: this.fb.control('', [
        Validators.required,
        Validators.email,
      ]),
      password: this.fb.control('', [Validators.required]),
      tenantId: this.fb.control(TenantId),
    });

    this.forgotEmail = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
    });
  }

  /**
   * used to login user
   */
  login(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    if (
      !this.loginForm.value.usernameOrEmail ||
      this.loginForm.value.usernameOrEmail === ''
    ) {
      return;
    }

    if (
      !this.loginForm.value.password ||
      this.loginForm.value.password === ''
    ) {
      return;
    }

    this.isLoading = true;
    const url = APP_URLS.login;
    this.Us.postCall(url, this.loginForm.value).subscribe(
      (res: any) => {
        this.submitted = false;
        this.isLoading = false;
        if (res?.errorCode === '10001') {
          this.Us.showError(res?.errorMessage);
        } else {
          localStorage.setItem('token', res.tokenData.access_token);
          localStorage.setItem('userDetails', JSON.stringify(res.tokenData));
          this.Us.showSuccess(this.resourceData.login_success);
          this.loginForm.reset({
            tenantId: TenantId,
          });
          this.Us.setLoggedUserDetails(res.tokenData);
          this.router.navigate(['crm/dashboard']);
          // window.location.reload();
        }
      },
      (error) => {
        this.Us.showError('Login Failed');
      }
    );
  }

  /**
   * used to change tab betwwen forgot password & login
   */
  changeTabForgotPass(data: string): void {
    this.submitted = false;
    this.submittedFp = false;
    this.isLoading = false;
    this.loginForm.reset({
      tenantId: TenantId,
    });

    this.forgotEmail.reset();
    this.isTabEnable = data;
  }

  /**
   * used for send mail for forgot password
   */
  forgotPassword(): void {
    this.submittedFp = true;
    if (this.forgotEmail.invalid) {
      return;
    }

    this.isLoading = true;
    const url =
      APP_URLS.forgotPassword +
      '?email=' +
      this.forgotEmail.get('email').value +
      '&tenantId=' +
      TenantId;

    this.Us.getCall(url).subscribe(
      (res: any) => {
        this.submittedFp = false;
        this.isLoading = false;
        if (res?.respCode === 204) {
          this.forgotEmail.reset();
          this.Us.showSuccess(res?.respMessage);
        }
        if (res?.errorCode === '10001') {
          this.Us.showError(res?.respMessage);
        } else {
          this.Us.showError(res?.respMessage);
        }
      },
      (err) => {
        this.isLoading = false;
        this.Us.showError('Network error');
      }
    );
  }
}
