<!-- Toolbar -->
<div class="toolbar" role="banner">
    <img width="150" alt="Logo" src="./assets/image/brugu-logo.png" />

    <div>
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div class="navbar-nav">
                    <a class="nav-item nav-link active" href="javascript:void(0)" (click)="navigateUrl('home')">Home</a>
                    <a class="nav-item nav-link" href="javascript:void(0)" (click)="navigateUrl('signin')">Login</a>
                </div>
            </div>
        </nav>
    </div>

</div>