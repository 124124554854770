<!-- ---------------------------------------------------------CRM TopNavbar Start------------------------------------------------------------------ -->

<div class="bg w-100">
    <div class="row mx-1 me-3" style="align-items: center;">
        <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12">
            <img style="height: 40px !important;background-size: cover;" [attr.src]="tenantLogo" class="img-fluid" />
        </div>
        <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-1">
            <button *ngIf="validateMenuPrivilages(107) === true" class="btn btn-primary btn-sm" data-bs-toggle="offcanvas" data-bs-target="#add-student-form" aria-controls="education-details-form" (click)="isAddStudentPopupOpen = true">Add Lead</button>
        </div>
        <!-- ---------------------------------------------------------Add Student Start------------------------------------------------------------------ -->

        <div class="offcanvas offcanvas-end add-student-model" tabindex="-1" id="add-student-form" aria-labelledby="education-details-formLabel">
            <div class="offcanvas-header">
                <h5 id="personal-info-formLabel">Add New Lead</h5>
                <button type="button" class="btn-danger" id="closeaddStudentPopup" data-bs-dismiss="offcanvas" aria-label="Close" (click)="isAddStudentPopupOpen = false"><span>Close</span></button>
            </div>
            <div class="offcanvas-body">
                <app-partner-add-form *ngIf="isAddStudentPopupOpen" (completePartnerProfile)="completedAddStudent($event)"></app-partner-add-form>
            </div>
        </div>
        <!-- ---------------------------------------------------------Add Student End------------------------------------------------------------------ -->

        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="d-flex" *ngIf="validateMenuPrivilages(100) === true || validateMenuPrivilages(101) === true || validateMenuPrivilages(105) === true || validateMenuPrivilages(106) === true || validateMenuPrivilages(107) === true">
                <div class="search-panel all_btn h40" style="width: 25% !important">
                    <!-- <button type="button" class="btn btn-default dropdown-toggle mt-1 w-100" data-toggle="dropdown">
                      <span id="search_concept text-white text-capitalize">{{searchTypeLabel}}</span> <span class="caret ms-3"></span>
                    </button> -->
                    <select name="option" class="btn btn-default dropdown-toggle mt-1 w-100" id="selectoption" (change)="selectData($event)">
                      <option *ngIf="validateMenuPrivilages(100) === true" value="student" class="text-start">Students</option>
                      <!-- <option value="institution" class="text-start">Universities</option> -->
                      <option *ngIf="validateMenuPrivilages(101) === true" value="course" class="text-start">Courses</option>
                      <option *ngIf="validateMenuPrivilages(105) === true" value="associate" class="text-start">Vendors</option>
                      <option *ngIf="validateMenuPrivilages(106) === true" value="employee" class="text-start">Employee</option>
                      <option *ngIf="validateMenuPrivilages(107) === true" value="contact" class="text-start">Leads</option>
                    </select>
                    <!-- <ul class="dropdown-menu scrollable-dropdown" role="menu" style="padding-left: 15px;">
                        <li><a href="javascript:void(0)">{{ resourceData.student }}</a></li>
                    </ul> -->
                </div>

                <div class="w-100">
                    <ng-autocomplete [data]="searchList" [searchKeyword]="keyword" [(ngModel)]="searchData" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' [itemTemplate]="itemTemplate" [placeholder]="'Search here...'" [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <div class="d-flex">
                            <a href="javascript:void(0)">
                                <span [innerHTML]="item?.first_name"></span>
                            </a>
                        </div>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
                <div class="">
                    <div class="bg-primary search h-100 px-3" style="border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important; padding-top: 25%;">
                        <i class="fa fa-search" aria-hidden="true" style="color: #fff;"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-2 text-white d-flex justify-content-end">
            <div class="row" style="margin-top: inherit;">
                <ul class="d-flex">
                    <li class="px-2 text-white">{{ resourceData.help_center }}</li>
                    <!-- <li class="px-2">FAQ</li>
                    <li class="bl_br"><i class="fas fa-ellipsis-h px-2"></i></li> -->
                    <li class="d-flex">
                        <div class="badge_css pointer" (click)="openNotification('inbox')">
                            <i class="far fa-envelope"></i>
                            <span class="css_badge badge bg-danger">
                              {{ inboxesData?.pagination?.totalRecords }}
                        </span>
                        </div>
                        <div>
                            <div class="badge_css pointer" (click)="openNotification('notification')">
                                <i class="far fa-bell"><span class="css_badge badge bg-success">
                                  {{ notificationData?.notifyCount }}
                                </span></i>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown" style="margin-top: -6px;">
                            <a class="btn text-white dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{ userDetails?.name }}">{{ userDetails?.name | truncate: 20 }}</span>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="javascript:void(0);" (click)="goToProfile()">Profile</a>
                                <a class="dropdown-item" *ngIf="userDetails?.role?.value === 'Admin'" href="javascript:void(0);" [routerLink]="['settings']">Settings</a>
                                <a *ngIf="validateMenuPrivilage(10000)" class="dropdown-item" href="javascript:void(0);" [routerLink]="['reports']">Reports</a>
                                <a class="dropdown-item" href="javascript:void(0);" (click)="logout()">Logout</a>
                            </div>
                            <img [src]="userDetails?.profile_picture !='' ? FILE_PATH_LINODE+'employee/'+userDetails.profile_picture : './assets/images/avatars/1.jpg'" class="rounded-circle" style="width: 30px;" />

                        </div>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</div>

<!-- ---------------------------------------------------------CRM TopNavbar End------------------------------------------------------------------ -->

<!-- ---------------------------------------------------------inbox Notification Start------------------------------------------------------------------ -->


<div class="offcanvas offcanvas-end" style="width: 60% !important;" tabindex="-1" id="open-notification-view" data-bs-backdrop="true" aria-labelledby="NotificationLabel">
    <div class="offcanvas-header">
        <h5 *ngIf="selectedType === 'notification'" id="offcanvas-notification">Notification</h5>
        <h5 *ngIf="selectedType === 'inbox'" id="offcanvas-inbox-notification">Inbox Notification</h5>
        <button type="button" id="close-notification-view" class="btn-danger" (click)="closeNotificationCanvas()" aria-label="Close"><span>Close</span></button>
    </div>
    <div class="offcanvas-body no-scroll-x pt-0">
        <app-inbox-notification-view *ngIf="isOpenNotification" [selectedNotifyData]="notificationData" [selectedInboxData]="inboxesData" [selectedType]="selectedType" [userDetails]="userDetails" (closeInboxPopup)='closeNotificationCanvas()'></app-inbox-notification-view>
    </div>
</div>



<!-- ---------------------------------------------------------inbox Notification End------------------------------------------------------------------ -->
