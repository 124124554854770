<div class="container my-5 d-flex justify-content-center">
    <div class="row justify-content-center ">
        <div class="col">
            <div class="card ">
                <div class="card-header pb-0 bg-white border-0 text-center px-sm-4">
                    <h6 class="text-left mt-4 font-weight-bold mb-0">
                        <span>
                            <i class="fa fa-times-circle fa-2x mr-3 " aria-hidden="true"></i> 
                        </span>
                         No internet connection
                        </h6> 
                        <span class="img-1 text-center">
                            <img src="./assets/image/no-internet.gif" class="img-fluid my-4 " />
                        </span>
                </div>
            </div>
        </div>
    </div>
</div>