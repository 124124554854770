import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[numbersOnly]',
})
export class NumbersOnlyInputDirective {
  // constructor(private elRef: ElementRef) {}

  // @HostListener('input', ['$event'])
  // onInputChange(event: { stopPropagation: () => void }) {
  //   const initalValue = this.elRef.nativeElement.value;
  //   this.elRef.nativeElement.value = initalValue.replace(/[^0-9+]*/g, '');
  //   if (initalValue !== this.elRef.nativeElement.value) {
  //     event.stopPropagation();
  //   }
  // }

  // private readonly regexStr = '^[0-9]*$';
  private readonly regEx = new RegExp('^[0-9]*$');

  constructor(private el: ElementRef) {}

  @Input() OnlyNumber = true;
  @Input() maxlength = 5;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    // if (this.OnlyNumber) {
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }

    // let ch = String.fromCharCode(e.keyCode);
    // let ch = event.key;
    // const current: string = this.el.nativeElement.value;
    // const next: string = current.concat(ch);
    // if(this.regEx.test(ch) && !this.isOverSize(next))
    if (!this.isValid(event.key)) { e.preventDefault(); }

    // }
  }

  private isValid(elegible: string): boolean {
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(elegible);
    return this.regEx.test(elegible);
  }
}
