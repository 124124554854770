import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthenticationGuard } from './authentication.guard';
import { NoInternetComponent } from './components/no-internet/no-internet.component';
import { CrmMainComponent } from './crm/components/crm-main/crm-main.component';
import { CrmAuthGuardGuard } from './crm/crm-auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  // {
  //   path: 'home',
  //   component: HomeComponent,
  //   canActivate: [AuthenticationGuard],
  // },
  // {
  //   path: 'signup',
  //   component: SignupComponent,
  // },
  {
    path: 'signin',
    component: SigninComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'crm',
    component: CrmMainComponent,
    canActivate: [CrmAuthGuardGuard],
    data: {
      unAuthRoles: 'ROLE_STUDENT',
    },
    loadChildren: () => import('./crm/crm.module').then((crm) => crm.CrmModule),
  },
  {
    path: 'no-internet',
    component: NoInternetComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
