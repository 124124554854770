import { Component, EventEmitter, OnInit } from '@angular/core';
import { Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/userservice.service';
import { APP_CRM_URLS } from 'src/app/Utils/crm';
import * as ResourceJson from '../../../../../../Utils/crm-resource.json';

@Component({
  selector: 'app-partner-add-form',
  templateUrl: './partner-add-form.component.html',
  styleUrls: ['./partner-add-form.component.css'],
})
export class PartnerAddFormComponent implements OnInit {
  @Input() partnerData: any;
  @Output() completePartnerProfile = new EventEmitter<any>();
  partner_info: FormGroup;
  maxDate: any;
  submitted = false;
  eventChange = 'password';
  eyeIcon = 'fas fa-eye';
  listOfRoles: any = [];
  isLoading = false;
  resourceData: any = (ResourceJson as any).default; // used for read resource file
  address: any;
  addresses: any = [];
  state: any;
  country: any;
  city: any;
  pincode: any;
  userDetails: any;
  occupationData = false;

  constructor(private fb: FormBuilder, private Us: UserService) {
    const todayDate = new Date();
    this.maxDate = todayDate.toISOString().substring(0, 10);
  }

  /**
   * get form controls for employee info form
   */
  get f() {
    return this.partner_info.controls;
  }

  ngOnInit(): void {
    const d = new Date();
    const time = d.toLocaleTimeString();
    /**
     * Initialize add form
     */
    this.partner_info = this.fb.group({
      name: this.fb.control('', [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
      // password: this.fb.control('', [
      //   Validators.required,
      //   Validators.minLength(5),
      //   Validators.maxLength(20),
      //   Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      // ]),
      phone: this.fb.control('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(15), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
      ]),
      company_name: this.fb.control(''),
      contact_date: this.fb.control('', [
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      contact_time: this.fb.control(time),
      occupation: this.fb.control('', [Validators.required]),
      businessName: this.fb.control(''),
      businessWebsite: this.fb.control(''),
      address: this.fb.control(
        ''),
      city: this.fb.control(''),
      state: this.fb.control(''),
      country: this.fb.control(
        '',
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
      ),
      messageBox: this.fb.control(''),
      contact_Id: this.fb.control(''),
      whatsapp_Link: this.fb.control(''),
      telegram_Link: this.fb.control('')
    });
  }

  password_show_hide() {
    if (this.eventChange === 'password') {
      this.eventChange = 'text';
      this.eyeIcon = 'fas fa-eye-slash';
    } else {
      this.eventChange = 'password';
      this.eyeIcon = 'fas fa-eye';
    }
  }

  disableDate() {
    return false;
  }

  selectAwards(data: any): void{
    if (data === 'Business Role' || data === 'Middle man'){
      this.occupationData = true;
      // this.partner_info.get('businessName')
      //     .setValidators([
      //       Validators.required,
      //       Validators.pattern(/^[a-zA-Z _\\\/.()&-]+$/),
      //       Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      //     ]);
      this.partner_info.get('businessWebsite')
      .setValidators([
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.pattern(
          /(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/
        )
      ]);
    }else{
      this.occupationData = false;
      this.partner_info.get('businessName').clearValidators();
      this.partner_info.patchValue({
        businessName: ''
      });
      this.partner_info.get('businessWebsite').clearValidators();
      this.partner_info.patchValue({
        businessName: ''
      });
    }
    }

  /**
   * Submitted partner details
   */
  submittedPartnerData(): void {
    this.submitted = true;
    if (this.partner_info.invalid) {
      return;
    }else{

      const addresses = [
        {
          city: { name: this.partner_info.value.city, key: '' },
          state: { name: this.partner_info.value.state, key: '' },
          country: { name: this.partner_info.value.country, key: '1001' },
          line1: '',
          address: this.partner_info.value.address,
          postal_code: this.partner_info.value.postal_code,
          sublocality: {},
          locality: {},
          operation: 0,
        },
      ];
      this.isLoading = true;
      const payload = {
        email: this.partner_info.value.email,
        name: this.partner_info.value.name,
        note: this.partner_info.value.messageBox,
        occupation: this.partner_info.value.occupation,
        business_name: this.partner_info.value.businessName,
        website: this.partner_info.value.businessWebsite,
        phone: this.partner_info.value.phone,
        company_name: this.partner_info.value.company_name,
        contact_date: this.partner_info.value.contact_date,
        contact_time: this.partner_info.value.contact_time,
        contact_Id: this.partner_info.value.contact_Id,
        whatsapp_Link: this.partner_info.value.whatsapp_Link,
        telegram_Link: this.partner_info.value.telegram_Link,
        created_new:
          this.partner_info.value.contact_date +
          '~~' +
          this.partner_info.value.contact_time,
        addresses,
      };
      // if (this.partner_info.value.password !== '') {
      //   payload.password = this.partner_info.value.password;
      // }
      const url = APP_CRM_URLS.contacts;
      this.Us.postCallwithHeaders(url, payload).subscribe(
        (res: any) => {
          this.submitted = false;
          this.isLoading = false;
          if (res.respCode === 400) {
            this.Us.showError(res.message);
          } else if (res.respCode === 201) {
            this.Us.showSuccess(
              this.resourceData.Partner_registered_successfully
            );
            this.completePartnerProfile.emit(res?.details);
          } else {
            this.Us.showError(res.respMessage);
          }
        },
        (error: any) => {
          this.Us.showError('Register Failed');
        }
      );
    }

    // if (this.partner_info.invalid) {
    //   console.log(this.partner_info);
    //   if (this.partner_info.value.password === '') {
    //     this.Us.showError(this.resourceData.password_filed_required);
    //   }
    //   if (
    //     this.partner_info.value.password.length > 0 &&
    //     this.partner_info.value.password.length < 5
    //   ) {
    //     this.Us.showError(this.resourceData.password_min_length);
    //     return;
    //   }
    //   return;
    // } else {

    //   if (this.partner_info.value.password.length > 10) {
    //     this.Us.showError(this.resourceData.password_max_length);
    //     return;
    //   }
    //   const addresses = [
    //     {
    //       city: { name: this.partner_info.value.city, key: '' },
    //       state: { name: this.partner_info.value.state, key: '' },
    //       country: { name: this.partner_info.value.country, key: '1001' },
    //       line1: '',
    //       address: this.partner_info.value.address,
    //       postal_code: this.partner_info.value.postal_code,
    //       sublocality: {},
    //       locality: {},
    //       operation: 0,
    //     },
    //   ];
    //   this.isLoading = true;
    //   const payload = {
    //     email: this.partner_info.value.email,
    //     name: this.partner_info.value.name,
    //     phone: this.partner_info.value.phone,
    //     company_name: this.partner_info.value.company_name,
    //     contact_date: this.partner_info.value.contact_date,
    //     contact_time: this.partner_info.value.contact_time,
    //     created_new:
    //       this.partner_info.value.contact_date +
    //       '~~' +
    //       this.partner_info.value.contact_time,
    //     addresses,
    //   };

    //   // if (this.partner_info.value.password !== '') {
    //   //   payload.password = this.partner_info.value.password;
    //   // }

    //   const url = APP_CRM_URLS.contacts;
    //   this.Us.postCallwithHeaders(url, payload).subscribe(
    //     (res: any) => {
    //       this.submitted = false;
    //       this.isLoading = false;
    //       if (res.respCode === 400) {
    //         this.Us.showError(res.message);
    //       } else if (res.respCode === 201) {
    //         this.Us.showSuccess(
    //           this.resourceData.Partner_registered_successfully
    //         );
    //         this.completePartnerProfile.emit(res?.details);
    //       } else {
    //         this.Us.showError(res.respMessage);
    //       }
    //     },
    //     (error: any) => {
    //       this.Us.showError('Register Failed');
    //     }
    //   );
    // }
  }
  /**
   * Edited Partner Details
   */
  editSubmittedPartnerData(): void {
    this.submitted = true;
    if (this.partner_info.invalid) {
      return;
    }
  }
}
