export const mainPermissions: any = {
  main_tabs: [
    { key: 100, value: 'Students' },
    { key: 101, value: 'Courses' },
    { key: 102, value: 'Universities' },
    { key: 103, value: 'Applications' },
    { key: 104, value: 'Attendances' },
    { key: 105, value: 'Associate' },
    { key: 106, value: 'Employees' },
    { key: 107, value: 'Contacts' },
    { key: 108, value: 'Templates' },
    { key: 109, value: 'Events' },
    { key: 110, value: 'Subjects' },
    { key: 111, value: 'Classrooms' },
    { key: 112, value: 'Invoices' },
    { key: 113, value: 'Case Study Download' },
    { key: 114, value: 'Blogs' },
    { key: 115, value: 'Tasks' },
    { key: 116, value: 'Appointments' },
    { key: 117, value: 'Testimonials' },
    { key: 118, value: 'Countries' },
    { key: 119, value: 'Chat' },
    { key: 120, value: 'Campaign' },
    { key: 121, value: 'SWM' },
    { key: 122, value: 'Departments' },
    { key: 123, value: 'CMS Pages' },
    { key: 124, value: 'Site Settings' },
    { key: 125, value: 'Faculty' },
    { key: 126, value: 'Meta Keywords' },
    { key: 127, value: 'Contact Users' },
    { key: 128, value: 'Admissions' },
    { key: 129, value: 'Download center' },
    { key: 130, value: 'Course Search' },
    { key: 131, value: 'My works' },
    { key: 132, value: 'My work tasks' },
    { key: 133, value: 'Transactions' },
    { key: 134, value: 'Lesson Plan & Log' },
    // { 'key': 135, 'value': 'OTJ settings' },
  ],
  dashboard_tabs: [
    { key: 1000, value: 'Dashboard Summary', main: true },
    { key: 1001, value: 'Student Details View', main: true },
    { key: 1002, value: 'Task', main: true },
    { key: 1151, value: 'Task Type Total', Task: true },
    { key: 1152, value: 'Task Type List', Task: true },
    { key: 1153, value: 'Task Type by Staff', Task: true }
],
  student_tabs: [
    // students main tabs start
    { key: 2000, value: 'Details', main: true },
    { key: 2019, value: 'Pre-Enrolment', main: true },
    { key: 2020, value: 'Enrolment', main: true },
    { key: 2021, value: 'Employment', main: true },
    { key: 2022, value: 'Delivery Management', main: true },
    { key: 2023, value: 'Learner Exit', main: true },
    { key: 2024, value: 'Agreements & Offer Letters', main: true },
    { key: 2026, value: 'My Work', main: true },
    { key: 2017, value: 'Off-The-Job', main: true },
    { key: 2001, value: 'My Applications', main: true },
    { key: 2014, value: 'Online Test', main: true },
    { key: 2004, value: 'Attendance', main: true },
    { key: 2016, value: 'Portfolio', main: true },
    { key: 2015, value: 'Certificates', main: true },
    { key: 2009, value: 'Appointments', main: true },
    { key: 2006, value: 'Notes', main: true },
    { key: 2007, value: 'Tasks', main: true },
    { key: 2008, value: 'Attachments', main: true },
    { key: 2011, value: 'Mailbox', main: true },
    { key: 2010, value: 'Timeline', main: true },
    { key: 2013, value: 'SMS', main: true },
    { key: 2012, value: 'Call Logs', main: true },
    // { key: 2003, value: 'Invoices & Fees', main: true },
    // { key: 2005, value: 'Profile Summary', main: true },
    // { key: 2018, value: 'Notifications', main: true },
    // { key: 2025, value: 'Welfare', main: true },
    // { key: 2036, value: 'University For You', main: true },
    // students main tabs end

    // students Details sub tabs start
    { key: 2100, value: 'Personal Information', sub: true },
    { key: 2115, value: 'Funding / Financials', sub: true },
    { key: 2109, value: 'Additional Info', sub: true },
    { key: 2101, value: 'Student IAG, IA and ILP', sub: true },
    { key: 2102, value: 'Educational Details', sub: true },
    { key: 2103, value: 'Work Experience', sub: true },
    { key: 2104, value: 'Passport & Visa', sub: true },
    { key: 2105, value: 'Referral Details', sub: true },
    { key: 2111, value: 'Emergency Contact', sub: true },
    { key: 2107, value: 'Checklist & Declarations', sub: true },
    // { key: 2106, value: 'Study Interest', sub: true },
    // { key: 2108, value: 'Test Taken', sub: true },
    // { key: 2110, value: 'Progress Tracker', sub: true },
    // { key: 2112, value: 'Fee', sub: true },
    // { key: 2113, value: 'CV', sub: true },
    // { key: 2114, value: 'Personal Statement', sub: true },
    // students Details sub tabs end

    // pre-enrolment sub tabs start
    { key: 2200, value: 'JS2.1: Equality and Diversity', Pre_Enrolment: true },
    { key: 2201, value: 'JS2.2: Course Identification', Pre_Enrolment: true },
    { key: 2202, value: 'JS2.3: IAG Interview', Pre_Enrolment: true },
    { key: 2203, value: 'JS2.4: Personal and Social Skills and Support Required', Pre_Enrolment: true },
    { key: 2204, value: 'JS2.5: Planned Learning Hours', Pre_Enrolment: true },
    { key: 2205, value: 'JS2.6: Career/Job Aspirations - Future Plans and Expectations', Pre_Enrolment: true },
    { key: 2206, value: 'JS2.7: Residence, Nationality and Household Situation', Pre_Enrolment: true },
    { key: 2207, value: 'JS2.8: Home / Overseas Details', Pre_Enrolment: true },
    { key: 2208, value: 'JS2.9: Verification of Identity & ID Documents', Pre_Enrolment: true },
    { key: 2209, value: 'JS2.10: Qualifications', Pre_Enrolment: true },
    { key: 2210, value: 'JS2.11: Safeguarding and Student Welfare', Pre_Enrolment: true },
    { key: 2211, value: 'JS2.12: Evidence Seen', Pre_Enrolment: true },
    { key: 2212, value: 'JS2.13: All Consents', Pre_Enrolment: true },
    { key: 2213, value: 'JS2.14: Additional Support Needs Questions', Pre_Enrolment: true },
    // pre-enrolment sub tabs end

    // Enrolment sub tabs start
    { key: 2300, value: 'JS3.1: Basic IA (Initial Assessment) Test/ Diagnostics', Enrolment: true },
    { key: 2301, value: 'JS3.2: Evidence to Support Accreditation Prior Learning (APL)', Enrolment: true },
    { key: 2302, value: 'JS3.3: Basic Assessment of RPL', Enrolment: true },
    { key: 2303, value: 'JS3.4: Training Provider Details', Enrolment: true },
    { key: 2304, value: 'JS3.5: Learning Aims  Mandatory Outcomes', Enrolment: true },
    { key: 2305, value: 'JS3.6:Qualification(s) check on LARA', Enrolment: true },
    { key: 2306, value: 'JS3.7: Modules Details', Enrolment: true },
    { key: 2307, value: 'JS3.8: Mode of Delivery/Location', Enrolment: true },
    { key: 2308, value: 'JS3.9: Pre-Entry Information, Advice and Guidance / Initial Assessment', Enrolment: true },
    { key: 2309, value: 'JS3.10: Planned Review Dates (no less than 12 weekly intervals)', Enrolment: true },
    { key: 2310, value: 'JS3.11: Induction at the Learning Centre', Enrolment: true },
    { key: 2311, value: 'JS3.12: How we Use Your Personal Information', Enrolment: true },
    { key: 2312, value: 'JS3.13: Individual Learning Plan/Signature', Enrolment: true },
    // Enrolment sub tabs end

    // Employment sub tab start
    { key: 2401, value: 'JS4.1:Employer Induction Pack & Video', Employment: true },
    { key: 2402, value: 'JS4.2: Employer / Organisation Details', Employment: true },
    { key: 2403, value: 'JS4.3: Manage Employment Information', Employment: true },
    { key: 2404, value: 'JS4.4: Employers Checklist and Consent', Employment: true },
    { key: 2405, value: 'JS4.5: Work Placement Health And Safety Assessment', Employment: true },
    { key: 2406, value: 'JS4.6: Protection Form', Employment: true },
    { key: 2407, value: 'JS4.7:Training Need Analysis', Employment: true },
    { key: 2408, value: 'JS4.8: Total Negotiated Price (TNP)', Employment: true },
    { key: 2409, value: 'JS4.9: Training and Learning provided by the Applied Business Academy', Employment: true },
    { key: 2410, value: 'JS4.10: Apprenticeship Agreement Checklist', Employment: true },
    { key: 2411, value: 'JS4.11: Commitment Statement', Employment: true },
    { key: 2412, value: 'JS4.12: Milestone', Employment: true },
    { key: 2413, value: 'JS4.13: Roles and Responsibilities', Employment: true },
    { key: 2414, value: 'JS4.14: Off The Job Training Calculator (OTJT)', Employment: true },
    // Employment sub tab end

    // Delivery_Management sub tab start
    { key: 2500, value: 'JS5.1: Diagnostics & Screeners for other subjects', Delivery_Management: true },
    { key: 2501, value: 'JS5.2: Assigning the Required Courses & Vendor Qualification', Delivery_Management: true },
    { key: 2502, value: 'JS5.3: Add Skill Coach', Delivery_Management: true },
    { key: 2503, value: 'JS5.4: Individual Learning Plan and Learning Support Plan', Delivery_Management: true },
    { key: 2504, value: 'JS5.5: Training Plan and Assessment Plan', Delivery_Management: true },
    { key: 2505, value: 'JS5.6: Learner Time Table', Delivery_Management: true },
    { key: 2506, value: 'JS5.7: Learn Scheme of Work (SOW)', Delivery_Management: true },
    { key: 2507, value: 'JS5.8: Lesson Plan', Delivery_Management: true },
    { key: 2508, value: 'JS5.9: Learner Classroom', Delivery_Management: true },
    { key: 2509, value: 'JS5.10: Learner Attendance', Delivery_Management: true },
    { key: 2510, value: 'JS5.11: Delivery Learner Support', Delivery_Management: true },
    { key: 2511, value: 'JS5.12: Full / Co- Funding Indicator', Delivery_Management: true },
    { key: 2512, value: 'JS5.13: Assessor Feedback to Learner', Delivery_Management: true },
    { key: 2513, value: 'JS5.14: Visit / Timesheet', Delivery_Management: true },
    { key: 2514, value: 'JS5.15: Non Regulated Aim Timesheet', Delivery_Management: true },
    { key: 2515, value: 'JS5.16: Change Log', Delivery_Management: true },
    { key: 2516, value: 'JS5.17: Start New Learning Activity / Assessment', Delivery_Management: true },
    { key: 2517, value: 'JS5.18: Calendar/Schedule Review', Delivery_Management: true },
    { key: 2518, value: 'JS5.19: Off The Job (OTJ)', Delivery_Management: true },
    { key: 2519, value: 'JS5.20: Journal Entry', Delivery_Management: true },
    { key: 2520, value: 'JS5.21: Progress Review', Delivery_Management: true },
    { key: 2521, value: 'JS5.22: Apprentice Review and Progress Record', Delivery_Management: true },
    // Delivery_Management sub tab end

    // Learner_Exit sub tab start
    { key: 2600, value: 'JS6.1: Agreed Break', Learner_Exit: true },
    { key: 2601, value: 'JS6.2: Withdrawal Form', Learner_Exit: true },
    { key: 2602, value: 'JS6.3: Certificates & Evidence', Learner_Exit: true },
    { key: 2603, value: 'JS6.4: Full Training and Assessment Evidence List/Completed', Learner_Exit: true },
    { key: 2604, value: 'JS6.5: Course Results/Course Progression Completed', Learner_Exit: true },
    { key: 2605, value: 'JS6.6: Completion Certificate', Learner_Exit: true },
    { key: 2606, value: 'JS6.7: Apprenticeship Checklist', Learner_Exit: true },
    { key: 2607, value: 'JS6.8: Individual Advice and Guidance - Exit', Learner_Exit: true },
    { key: 2608, value: 'JS6.9: Progression for ESF/ Learner Destination & Progression', Learner_Exit: true },
    { key: 2609, value: 'JS6.10: Job Search', Learner_Exit: true },
    { key: 2610, value: 'JS6.11: Learner Destination Tracking Form', Learner_Exit: true },
    { key: 2611, value: 'JS6.12: Learner Exit', Learner_Exit: true },
    { key: 2612, value: 'JS6.13: Learner Exit Checklist', Learner_Exit: true },
    // Learner_Exit sub tab end

    // Agreements & Offer Letters sub tab start
    { key: 2700, value: 'JS7.1: Individual Learning Agreement', Agreements_Offer_Letters: true },
    { key: 2701, value: 'JS7.2: Individual Learning Plan (ILP)', Agreements_Offer_Letters: true },
    { key: 2702, value: 'JS7.3: Employer Agreement', Agreements_Offer_Letters: true },
    { key: 2703, value: 'JS7.4: Conditional Offer', Agreements_Offer_Letters: true },
    { key: 2704, value: 'JS7.5: HE Student Finance Application Process', Agreements_Offer_Letters: true },
    { key: 2705, value: 'JS7.6: Enrollment Letter', Agreements_Offer_Letters: true },
    { key: 2706, value: 'JS7.7: Placement Letter', Agreements_Offer_Letters: true },
    { key: 2707, value: 'JS7.8: NESCOT Safeguarding Policy', Agreements_Offer_Letters: true },
    // Agreements & Offer Letters sub tab end

    // My Work sub tab start
    { key: 2800, value: 'Wallet', My_Work: true},
    { key: 2801, value: 'KYC', My_Work: true},
    { key: 2802, value: 'Withdraw', My_Work: true},
    // My Work sub tab end

    // Off_The_Job sub tab start
    { key: 2900, value: 'Overview', Off_The_Job: true },
    { key: 2901, value: 'OTJ Activity', Off_The_Job: true },
    { key: 2902, value: 'OTJ Pending Activities', Off_The_Job: true },
    { key: 2903, value: 'OTJ Completed Records', Off_The_Job: true },
    { key: 2904, value: 'Excluded OTJ Records', Off_The_Job: true },
    { key: 2905, value: 'VLE Records', Off_The_Job: true },
    { key: 2906, value: 'Attendance Records', Off_The_Job: true },
    { key: 2907, value: 'AT Lab Records', Off_The_Job: true },
    { key: 2908, value: 'My Spr', Off_The_Job: true },
    { key: 2909, value: 'Skill Scan', Off_The_Job: true },
    { key: 2910, value: 'Monthly Review', Off_The_Job: true },
    { key: 2911, value: 'Formal Review', Off_The_Job: true },
    { key: 2912, value: 'Matrix', Off_The_Job: true },
    { key: 2913, value: 'Progession SPR', Off_The_Job: true },
    // Off_The_Job sub tab end

    // { key: 1069, value: 'Edit Date', off_the_job: true, prevPermission: 1017 },
    // { key: 1070, value: 'Edit Date', Attendance: true, prevPermission: 1004 },
    // { key: 1084, value: 'My Shortlist', University_For_You: true },
    // { key: 1085, value: 'Applications', University_For_You: true },
],
university_tabs: [
  { key: 3000, value: 'Details', main: true },
  { key: 3001, value: 'Notes', main: true },
  { key: 3002, value: 'Attachments', main: true },
  { key: 3003, value: 'Timeline', main: true },
  { key: 3004, value: 'Ranks', main: true },
  { key: 3005, value: 'Terms & Events', main: true },
  { key: 3006, value: 'Testmonials', main: true },
  { key: 3007, value: 'Courses', main: true },
  { key: 3008, value: 'Task', main: true },
  { key: 3009, value: 'Appointment', main: true },
  { key: 3010, value: 'Mailbox', main: true },
  { key: 3011, value: 'SMS', main: true },
  // Details sub tab start
  { key: 3100, value: 'Institutions Information', sub: true },
  { key: 3101, value: 'Campus Details', sub: true },
  { key: 3102, value: 'Admission Contacts', sub: true },
  { key: 3103, value: 'Payment Options', sub: true },
  { key: 3104, value: 'Commission and its Terms', sub: true }
  // Details sub tab end

],
course_tabs: [
  // course main tab start
  { key: 4000, value: 'Details', main: true },
  { key: 4001, value: 'Attachments', main: true },
  { key: 4002, value: 'Notes', main: true },
  { key: 4003, value: 'Tasks', main: true },
  { key: 4004, value: 'Appointments', main: true },
  { key: 4005, value: 'Timeline', main: true },
  { key: 4006, value: 'Course Builder', main: true },
  { key: 4007, value: 'Mailbox', main: true },
  { key: 4008, value: 'SMS', main: true },
  // course main tab end

  // details sub tab start
  { key: 4100, value: 'Course Information', sub: true },
  { key: 4101, value: 'Available Course Intake', sub: true },
  { key: 4102, value: 'Course Contact', sub: true },
  { key: 4103, value: 'Mode of study', sub: true },
  { key: 4104, value: 'Course Module', sub: true },
  { key: 4105, value: 'Certificate Awarded By', sub: true },
  { key: 4106, value: 'Course Structure', sub: true },
  { key: 4107, value: 'Course Term', sub: true },
  { key: 4108, value: 'Course Type', sub: true },
  { key: 4109, value: 'Duration', sub: true },
  { key: 4110, value: 'Important Course Dates', sub: true },
  { key: 4111, value: 'Eligibility Requirements', sub: true },
  { key: 4112, value: 'Academy Site/Campus', sub: true },
  // details sub tab end

  // Course_Builder sub tab start
  { key: 4200, value: 'General Info', Course_Builder: true },
  { key: 4201, value: 'Course Material', Course_Builder: true },
  { key: 4202, value: 'Add Chapter', Course_Builder: true },
  { key: 4203, value: 'Add Download', Course_Builder: true },
  { key: 4204, value: 'Add Teacher', Course_Builder: true },
  { key: 4205, value: 'Add Chapter by Imported Course', Course_Builder: true },
  // Course_Builder sub tab end
],
employee_tabs: [
  // employee main tabs start
  { key: 5000, value: 'Details', main: true },
  { key: 5007, value: 'My Students', main: true },
  { key: 5008, value: 'My Team', main: true },
  { key: 5012, value: 'My Applications', main: true},
  { key: 5011, value: 'Universities list', main: true},
  { key: 5001, value: 'Notes', main: true },
  { key: 5002, value: 'Attachments', main: true },
  { key: 5009, value: 'Task', main: true },
  { key: 5005, value: 'Mailbox', main: true },
  { key: 5004, value: 'Timeline', main: true },

  // { key: 5003, value: 'Appointments', main: true },
  // { key: 5006, value: 'SMS', main: true },
  // { key: 5010, value: 'Feedback', main: true },
  // employee main tabs end

  // employee details sub tabs start
  { key: 5100, value: 'Personal Information', sub: true },
  { key: 5101, value: 'Signature', sub: true },
  // employee details sub tabs end

  // employee myteam sub tabs start
  { key: 5200, value: 'Employee', My_team: true },
  { key: 5201, value: 'Associate', My_team: true }
  // employee myteam sub tabs end

  // { 'key': 4009, 'value': 'Feedbacks', 'main': true },
  // { 'key': 4002, 'value': 'Tasks', 'main': true },

],
agent_tabs: [
  // agent_tabs main tabs start
  { key: 6000, value: 'Details', main: true },
  { key: 6007, value: 'My Students', main: true },
  { key: 6008, value: 'My Team', main: true },
  { key: 6011, value: 'Universities list', main: true},
  { key: 6001, value: 'Notes', main: true },
  { key: 6003, value: 'Attachments', main: true },
  { key: 6002, value: 'Tasks', main: true },
  { key: 6006, value: 'Mailbox', main: true },
  { key: 6005, value: 'Timeline', main: true },

  // { key: 6004, value: 'Appointments', main: true },
  // { key: 6009, value: 'Feedback', main: true },
  // { key: 6010, value: 'SMS', main: true },
  // { key: 6012, value: 'My Applications', main: true},
  // agent_tabs main tabs start

  // agent_tabs details sub tabs start
  { key: 6100, value: 'Personal Information', sub: true },
  { key: 6101, value: 'Signature', sub: true },
  // agent_tabs details sub tabs end

  // agent_tabs myteam sub tabs start
  { key: 6200, value: 'Employee', My_team: true },
  { key: 6201, value: 'Associate', My_team: true }
  // agent_tabs myteam sub tabs end
],
contact_tabs: [
  // partner Main tabs start
  { key: 7000, value: 'Details', main: true },
  { key: 7011, value: 'My Students', main: true },
  { key: 7008, value: 'My Works', main: true },
  { key: 7001, value: 'Notes', main: true },
  { key: 7002, value: 'Tasks', main: true },
  { key: 7003, value: 'Attachments', main: true },
  { key: 7005, value: 'Timeline', main: true },
  { key: 7006, value: 'Mailbox', main: true },
  { key: 7012, value: 'Call Logs', main: true },
  { key: 7010, value: 'SMS', main: true },

  // { key: 7004, value: 'Appointments', main: true },
  // { key: 7007, value: 'Projects', main: true },
  // { key: 7009, value: 'Feedback', main: true },
  // partner Main tabs end

  // partner details sub tabs start
  { key: 7100, value: 'Personal Information', sub: true },
  { key: 7101, value: 'Signature', sub: true },
  // partner details sub tabs end

  // My Works sub tabs start
  { key: 7200, value: 'Projects', My_Works: true },
  { key: 7201, value: 'My Task', My_Works: true }
  // My Works sub tabs end

],
  classroom_tabs: [
    // { key: 7000, value: 'Details', main: true },
    // { key: 7020, value: 'Details Summary', sub: true },
    // { key: 7021, value: 'Add Student', sub: true },
  ],
  other_tabs: [
    { key: 10000, value: 'Reports' },
    { key: 10001, value: 'Application Status' },
    { key: 10002, value: 'Invite Student' },
    { key: 10003, value: 'Invite Associate' },
    { key: 10004, value: 'Progress Status' },
    { key: 10005, value: 'Other' },
    { key: 10006, value: 'Import' },
    { key: 10007, value: 'Status Groups' },
    { key: 10008, value: 'Checklist Groups' },
    { key: 10009, value: 'Invite Freelance' },
    { key: 10010, value: 'Lesson Plan' }
  ],
  report_sub: [
    // report_sub tabs start
    { key: 10100, value: 'Report Overview', main: true },
    { key: 10101, value: 'Task', main: true },
    { key: 10102, value: 'Lead Source', main: true },
    { key: 10103, value: 'Profile Status', main: true },
    { key: 10104, value: 'Academic Reports', main: true },
    // report_sub tabs end
    // Task  sub tab start
    { key: 10200, value: 'Task Type Total', Task: true},
    { key: 10201, value: 'Task Type List', Task: true},
    { key: 10202, value: 'Task Type by Staff', Task: true},
    // Task  sub tab end
    // Lead Source  sub tab start
    { key: 10300, value: 'Lead Source Total', Lead_Source: true},
    { key: 10301, value: 'Lead Source List', Lead_Source: true},
    { key: 10302, value: 'Lead Source Staff', Lead_Source: true},
    // Lead_Source  sub tab end
    // Profile Status  sub tab start
    { key: 10400, value: 'Profile Status Total', Profile_Status: true},
    { key: 10401, value: 'Profile Status List', Profile_Status: true},
    { key: 10402, value: 'Profile Status Staff', Profile_Status: true},
    // Profile Status  sub tab end
    // Academic Reports  sub tab start
    { key: 10500, value: 'Activity Reports', Academic_Reports: true},
    { key: 10501, value: 'Performance Report', Academic_Reports: true},
    { key: 10502, value: 'Qualification Report', Academic_Reports: true},
    // Academic Reports  sub tab end


],
  my_profile_tabs: [
    { key: 8500, value: 'Profile summary' },
    { key: 8501, value: 'Manage address' },
    { key: 8502, value: 'Transaction details' },
    { key: 8503, value: 'Standard level/task' },
    { key: 8504, value: 'Certificates' },
    { key: 8505, value: 'Support tickets' },
    { key: 8506, value: 'Complaints' },
    { key: 8507, value: 'Feedbacks' },
    { key: 8508, value: 'Plagiarism' },
    { key: 8509, value: 'Notifications' },
    { key: 8510, value: 'Book For Interview' },
    { key: 8511, value: 'Progression' },
    { key: 8512, value: 'Admission Application' },
    { key: 8513, value: 'Action Center' },
  ],
  my_works_tabs: [
    { key: 8600, value: 'My Tasks', role: 'Student' },
    { key: 8601, value: 'Wallet', role: 'Student' },
    { key: 8602, value: 'KYC', role: 'Student' },
    { key: 8603, value: 'Withdraw', role: 'Student' },
    { key: 8604, value: 'Channel Account', role: 'Student' },

    { key: 8606, value: 'My Projects', role: 'Employer' },
    { key: 8607, value: 'My Tasks', role: 'Employer' },
    // { 'key': 8610, 'value': 'My works' },
  ],
  contact_profile_tabs: [{ key: 8700, value: 'Profile summary' }],
  my_otj_tabs: [
    { key: 8800, value: 'OTJ Activity' },
    { key: 8801, value: 'OTJ Pending Activities' },
    { key: 8802, value: 'OTJ Completed Records' },
    { key: 8803, value: 'Excluded OTJ Records' },
    { key: 8804, value: 'VLE Records' },
    { key: 8805, value: 'Attendance Records' },
    { key: 8806, value: 'AT Lab Records' },
    { key: 8807, value: 'Progression' },
  ],
  application_tabs: [
    { key: 9010, value: 'Application Details', VLE_Course: true },
    { key: 9011, value: 'Tasks', VLE_Course: true },
    { key: 9012, value: 'Notes', VLE_Course: true },
    { key: 9013, value: 'Attachments', VLE_Course: true },
    { key: 9014, value: 'Mailbox', VLE_Course: true },
    { key: 9015, value: 'Timeline', VLE_Course: true },

    { key: 9020, value: 'Details', Application_Details_VLE_Course: true },
    { key: 9021, value: 'Download Pdf', Application_Details_VLE_Course: true },
    { key: 9022, value: 'Send via Email', Application_Details_VLE_Course: true }
  ],
  mail_permissions: [
    {
      key: 9500,
      menu: 'student',
      label: 'Assign employee (Student allocated)',
    },
    {
      key: 9501,
      menu: 'contact',
      label: 'Contact create (send email to employee (only admin))',
    },
    {
      key: 9502,
      menu: 'employee',
      label: 'Employee/Associate forgot password',
    },
    { key: 9503, menu: 'employee', label: 'Employee set password' },
    { key: 9504, menu: 'student', label: 'Inbox' },
    { key: 9505, menu: 'agent', label: 'Invite Associate' },
    { key: 9506, menu: 'student', label: 'Invite student' },
    {
      key: 9507,
      menu: 'student',
      label: 'Student create (Activate your account)',
    },
    {
      key: 9508,
      menu: 'student',
      label: 'Student create (send email to employee (only admin))',
    },
    { key: 9509, menu: 'student', label: 'Student forgot password' },
    {
      key: 9510,
      menu: 'student',
      label: 'Sending email to employees (Create appointment)',
    },
    { key: 9511, menu: 'agent', label: 'Associate set password' },
    {
      key: 9512,
      menu: 'student',
      label: 'Student Registration (Welcome email)',
    },
    { key: 9513, menu: 'contact', label: 'Contact (Welcome email)' },
    { key: 9514, menu: 'contact', label: 'Contact (Forgot password)' },
    { key: 9515, menu: 'employee', label: 'Employee (Welcome email)' },
    { key: 9516, menu: 'agent', label: 'Associate (Welcome email)' },
    { key: 9517, menu: 'student', label: 'Student (Payment Invoice)' },
    { key: 9518, menu: 'student', label: 'Student (Assignment Report)' },
    { key: 9519, menu: 'university', label: 'University (Payment Report)' },
    { key: 9520, menu: 'university', label: 'University (Welcome email)' },
    { key: 9521, menu: 'student', label: 'Course Application Status' },
    { key: 9522, menu: 'student', label: 'Certificate Template' },
    { key: 9523, menu: 'student', label: 'Student status change' },
    {
      key: 9524,
      menu: 'student',
      label: 'Course Application Status (toadmin)',
    },
    {
      key: 9525,
      menu: 'student',
      label: 'Course Application Status (toemployee)',
    },
    {
      key: 9526,
      menu: 'employee',
      label: 'Employee Welcome Email (send email to admin employee',
    },
    { key: 9527, menu: 'employee', label: 'Employee status change' },
    { key: 9528, menu: 'employee', label: 'Employee status change (toadmin)' },
    { key: 9529, menu: 'employee', label: 'Employee role change' },
    { key: 9530, menu: 'employee', label: 'Employee role change (toadmin)' },
    { key: 9531, menu: 'employee', label: 'Employee Allocation' },
    { key: 9532, menu: 'employee', label: 'Employee Allocation (toadmin)' },
    { key: 9533, menu: 'employee', label: 'Employee profile status change' },
    {
      key: 9534,
      menu: 'employee',
      label: 'Employee profile status change (toadmin)',
    },
    {
      key: 9535,
      menu: 'agent',
      label: 'Associate Welcome Email - send email to admin employee',
    },
    { key: 9536, menu: 'student', label: 'Student create (Bulk Upload)' },
  ],
  profile_status_tabs: [
    { key: 10000, value: 'Student Profile Status' },
    { key: 10001, value: 'University Profile Status' },
    { key: 10002, value: 'Associate Profile Status' },
    { key: 10003, value: 'Contact Profile Status' },
    { key: 10004, value: 'Student Lead Source Status' },
    { key: 10005, value: 'Checklist Status' },
    { key: 10006, value: 'Contact Lead Source Status' },
  ],
  plagiarism_tabs: [
    { key: 11000, value: 'Profile' },
    { key: 11001, value: 'Assignment' },
  ],
  settings_tabs: [
    { key: 20000, value: 'Role Permission' },
    { key: 20001, value: 'Employee Permission' },
    { key: 20002, value: 'Agnet Permission' },
    { key: 20003, value: 'Page Info Settings' },
    { key: 20004, value: 'Help Center' },
    { key: 20005, value: 'CSMS' },
    { key: 20006, value: 'Course Map' },
    { key: 20007, value: 'Academic Planner' },
    { key: 20008, value: 'My Work' },
    { key: 20009, value: 'Student Login' },
    { key: 20010, value: 'OTJ' },
    { key: 20011, value: 'AT Lab' },
  ],
  csms_tabs: [
    { key: 21000, value: 'CS Category' },
    { key: 21001, value: 'Standard Level' },
    { key: 21002, value: 'Activity Location' },
    { key: 21003, value: 'Teacher Levels' },
    { key: 21004, value: 'Competence Standard' },
    { key: 21005, value: 'Employee Mapping' },
    { key: 21006, value: 'Employee Allocation' },
    { key: 21007, value: 'Student Portfolio' },
    { key: 21008, value: 'Student Timesheet' },
    // { 'key': 21009, 'value': 'Resource Level'},
    // { 'key': 21010, 'value': 'Resource Level Mapping'},
    // { 'key': 21011, 'value': 'Operation ID'},
    // { 'key': 21012, 'value': 'Channels'},
    // { 'key': 21013, 'value': 'Tag Type'},
    // { 'key': 21014, 'value': 'Tag Manager'},
    { key: 21015, value: 'General Portfolio' },
    { key: 21016, value: 'Account Manager' },
  ],
  coursemap_tabs: [
    { key: 22000, value: 'Course Play' },
    { key: 22001, value: 'Test' },
    { key: 22002, value: 'Test(Q/A)' },
    { key: 22003, value: 'Assignment' },
    { key: 22004, value: 'Bundle Course Fee' },
    { key: 22005, value: 'Course Build' },
    { key: 22006, value: 'Practice Test' },
    { key: 22007, value: 'Feedback' },
  ],
  academic_planner_tabs: [
    { key: 23000, value: 'Select Course' },
    { key: 23001, value: 'Time Table' },
    { key: 23002, value: 'SOW' },
    { key: 23003, value: 'Lesson Plan & Delivery LP Log' },
    { key: 23004, value: 'Classroom' },
    { key: 23005, value: 'Attendance' },
  ],
  my_work_tabs: [
    { key: 24001, value: 'Resource Level' },
    { key: 24002, value: 'Resource Level Mapping' },
    { key: 24003, value: 'Tag Type' },
    { key: 24004, value: 'Tag Manager' },
    { key: 24005, value: 'Operation ID' },
    { key: 24006, value: 'Channels' },
    { key: 24007, value: 'Channel Account' },
  ],
  student_login_tabs: [
    { key: 25001, value: 'OTJ Settings' },
    { key: 25002, value: 'Qualification' },
    { key: 25003, value: 'Employment History' },
    { key: 25004, value: 'Personal Statement' },
    { key: 25005, value: 'Fee' },
    { key: 25006, value: 'Checklist & Declarations' },
  ],
  otj_tabs: [
    { key: 27001, value: 'OTJ Category' },
    { key: 27001, value: 'OTJ Main Stage' },
    { key: 27001, value: 'OTJ Activity' },
  ],
  at_labs_tabs: [
    { key: 28001, value: 'AT Lab Group' },
    { key: 28001, value: 'AT Lab Parent' },
    { key: 28001, value: 'AT Lab Activity' },
  ],
};
